import { Component } from 'preact';
import { SectionConfig } from '../../services/menu.service';
import { Heading } from '../ui/heading/heading';
import styles from './partners.module.css';

export class Partners extends Component<Props> {
    public render() {
        const { section } = this.props;
        return (
            <section className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.heading}>
                        <Heading id={section.id} size='h2' underline='center'>
                            {section.title}
                        </Heading>
                    </div>
                    <div className={styles.partnersWrapper}>
                        {this.partners.map((partner) => (
                            <div className={styles.partner}>
                                <div className={styles.partnerImage}>
                                    {partner.image && (
                                        <img src={`../../assets/partners/${partner.image}`} alt={partner.name} />
                                    )}
                                </div>
                                <div className={styles.partnerDescription}>
                                    <div className={styles.partnerName}>
                                        <Heading size='h4'>{partner.name}</Heading>
                                    </div>
                                    <div className={styles.partnerTitle}>{partner.title}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    private get partners(): Partner[] {
        return [
            {
                id: 'jpaluszkiewicz',
                name: 'Joanna Paluszkiewicz',
                title: 'Partner zarządzający',
                image: 'jpaluszkiewicz.jpg'
            },
            {
                id: 'ola',
                name: 'Aleksandra Urbańska',
                title: 'Aplikant radcowski',
                image: null
            },
            {
                id: 'patrycja',
                name: 'Patrycja Woźniewska',
                title: 'Doradca podatkowy',
                image: null
            }
        ];
    }
}

interface Props {
    section: SectionConfig;
}

interface Partner {
    id: string;
    name: string;
    title: string;
    image: string | null;
}
