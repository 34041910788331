import { Component } from 'preact';
import styles from './map.module.css';

export class Map extends Component {
    public render() {
        return (
            <div className={styles.map}>
                <iframe
                    width='100%'
                    height='450'
                    style='border:0'
                    loading='lazy'
                    src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ3V_fKDlFBEcRa9nzDkqjhCI&key=AIzaSyDtMcY544YFZ2IINDyrMc5ILeFE9MtGg1M'></iframe>
            </div>
        );
    }
}
