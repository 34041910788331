import { Component } from 'preact';
import styles from './card.module.css';

export class Card extends Component<Props> {
    public render() {
        return <div className={this.props.dark ? styles.dark : styles.light}>{this.props.children}</div>;
    }
}

interface Props {
    dark?: boolean;
}
