import { Component } from 'preact';
import styles from './menu-item.module.css';

export class MenuItem extends Component<Props> {
    public render() {
        return (
            <a href={`#${this.props.anchor}`} className={styles.menuItem} onClick={this.props.onClick}>
                {this.props.children}
            </a>
        );
    }
}

interface Props {
    anchor: string;
    onClick: () => void;
}
