import { Component } from 'preact';
import { SectionConfig } from '../../services/menu.service';
import { Card } from '../ui/card/card';
import { Heading } from '../ui/heading/heading';
import { Paragraph } from '../ui/paragraph/paragraph';
import { getPractices, Practice } from './legal-practices.service';
import styles from './legal-practices.module.css';

export class LegalPractices extends Component<Props> {
    public render() {
        const { section } = this.props;
        return (
            <div>
                <section className={styles.header}>
                    <Heading id={section.id} underline='center' size='h2' align='center'>
                        {section.title}
                    </Heading>
                </section>
                <div className={styles.practiceList}>
                    {this.practices.map((practice, i) => (
                        <Card dark={this.isDark(i)}>
                            <div className={styles.practice}>
                                <Heading light={this.isDark(i)}>{practice.title}</Heading>
                                <Paragraph light={this.isDark(i)}>{practice.text}</Paragraph>
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }

    private get practices(): Practice[] {
        return getPractices();
    }

    private isDark(value: number): boolean {
        return !!(value % 2);
    }
}
interface Props {
    section: SectionConfig;
}
