import { Component } from 'preact';
import styles from './logo.module.css';

export class Logo extends Component {
    public render() {
        return (
            <div className={styles.logo}>
                <a href='/'>
                    <img
                        className={styles.logoImage}
                        src='../../assets/paluszkiewicz_white.svg'
                        alt='Paluszkiewicz Kancelaria Radcy Prawnego'
                    />
                </a>
            </div>
        );
    }
}
