import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'preact';
import style from './top-contact-link.module.css';

export class TopContactLink extends Component<TopContactLinkProps> {
    public render() {
        return (
            <div>
                <FontAwesomeIcon icon={this.icon} className={style.icon} />
                <a className={style.link} href={this.link}>
                    {this.props.text}
                </a>
            </div>
        );
    }

    private get link(): string {
        if (this.props.type === 'email') {
            return `mailto:${this.props.text}`;
        }

        return 'tel';
    }

    private get icon(): IconDefinition {
        if (this.props.type === 'email') {
            return faEnvelope;
        }

        return faPhoneAlt;
    }
}

interface TopContactLinkProps {
    text: string;
    type: 'email' | 'phone';
}
