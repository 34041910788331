import { Component } from 'preact';
import styles from './main-heading.module.css';

export class MainHeading extends Component {
    public render() {
        return (
            <>
                <h1 className={styles.heading}>{this.props.children}</h1>
                <div className={styles.underlineContainer}>
                    <hr className={styles.underline} />
                </div>
            </>
        );
    }
}
