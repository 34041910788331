import { Component } from 'preact';
import styles from './heading.module.css';

export class Heading extends Component<Props> {
    static defaultProps = {
        id: '',
        size: 'h3'
    };
    public render() {
        return (
            <div>
                {this.props.size === 'h2' && (
                    <h2 id={this.props.id} className={this.headingStyle}>
                        {this.props.children}
                    </h2>
                )}
                {this.props.size === 'h3' && (
                    <h3 id={this.props.id} className={this.headingStyle}>
                        {this.props.children}
                    </h3>
                )}
                {this.props.size === 'h4' && (
                    <h4 id={this.props.id} className={this.headingStyle}>
                        {this.props.children}
                    </h4>
                )}
                {this.props.underline && (
                    <div className={this.underlineStyle}>
                        <div className={styles.underline}></div>
                    </div>
                )}
            </div>
        );
    }

    private get headingStyle(): string {
        const mode = this.props.light ? styles.light : styles.dark;
        const alignCenter = this.props.align === 'center' ? styles.headingCenter : '';
        return [styles.heading, mode, alignCenter].join(' ');
    }

    private get underlineStyle(): string {
        const basic = styles.underlineWrapper;
        if (this.props.underline === 'center') {
            return [basic, styles.center].join(' ');
        }
        return basic;
    }
}

interface Props {
    id?: string;
    size?: 'h2' | 'h3' | 'h4';
    light?: boolean;
    underline?: 'left' | 'center';
    align?: 'left' | 'center';
}
