import { Component } from 'preact';
import styles from './paragraph.module.css';

export class Paragraph extends Component<Props> {
    public render() {
        return <p className={this.style}>{this.props.children}</p>;
    }

    private get style(): string {
        const mode = this.props.light ? styles.light : styles.dark;
        return [styles.paragraph, mode].join(' ');
    }
}

interface Props {
    light?: boolean;
}
