export function getMenu(): MenuConfig {
    return {
        about: {
            id: 'o-kancelarii',
            title: 'O kancelarii'
        },
        legalPracticesArea: {
            id: 'obszar-praktyk-prawnych',
            title: 'Obszar praktyk prawnych'
        },
        team: {
            id: 'zespol',
            title: 'Zespół'
        },
        contact: {
            id: 'kontakt',
            title: 'Kontakt'
        }
    };
}

export interface MenuConfig {
    [key: string]: SectionConfig;
}
export interface SectionConfig {
    id: string;
    title: string;
}
