import { Component } from 'preact';
import { Header } from './header/header';
import { TopContact } from './top-contact/top-contact';
import styles from './app.module.css';
import { About } from './about/about';
import { Footer } from './footer/footer';
import { LegalPractices } from './legal-practices/legal-practices';
import { Contact } from './contact/contact';
import { Clients } from './clients/clients';
import { Partners } from './partners/partners';
import { getMenu, MenuConfig } from '../services/menu.service';

export class App extends Component {
    public render() {
        const email = 'kancelaria@paluszkiewicz.legal';
        const phone = '510 69 00 69';
        return (
            <div id='app'>
                {false && (
                    <div className={styles.container}>
                        <div className={styles.item}>
                            <TopContact />
                        </div>
                    </div>
                )}
                <Header menuConfig={this.menu} />
                <div className={`${styles.aboutContainer} ${styles.cell}`}>
                    <About section={this.menu.about} />
                </div>
                <div className={`${styles.legalPracticesContainer} ${styles.cell}`}>
                    <LegalPractices section={this.menu.legalPracticesArea} />
                </div>
                <Partners section={this.menu.team} />
                <div className={styles.contactContainer}>
                    <div className={styles.cell}>
                        <Contact section={this.menu.contact} email={email} phone={phone} />
                    </div>
                </div>
                {/* <Clients /> */}
                <Footer />
            </div>
        );
    }

    private get menu(): MenuConfig {
        return getMenu();
    }
}
