import { Component } from 'preact';
import { SectionConfig } from '../../services/menu.service';
import { Map } from '../map/map';
import { Heading } from '../ui/heading/heading';
import { Paragraph } from '../ui/paragraph/paragraph';
import styles from './contact.module.css';

export class Contact extends Component<Props> {
    public render() {
        const { email, phone, section } = this.props;
        return (
            <section className={styles.wrapper}>
                <div className={styles.contact}>
                    <div>
                        <Heading id={section.id} underline='left' size='h2'>
                            {section.title}
                        </Heading>
                    </div>

                    <Paragraph>
                        Telefon: <a href='tel'>{phone}</a>
                    </Paragraph>
                    <Paragraph>
                        Email: <a href={`mailto:${email}`}>{email}</a>
                    </Paragraph>
                    <Paragraph>
                        ul. Kopanina 28/32, lokal 407
                        <br />
                        60-105 Poznań
                    </Paragraph>
                    <Paragraph>Pon - pt: 09:00 - 17:00</Paragraph>
                </div>
                <div className={styles.map}>
                    <Map />
                </div>
            </section>
        );
    }
}
interface Props {
    email: string;
    phone: string;
    section: SectionConfig;
}
