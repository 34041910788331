import { Component } from 'preact';
import styles from './footer.module.css';

export class Footer extends Component {
    public render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.footer}>
                    <div className={styles.copyright}>
                        <span>
                            Copyright © 2021 Kancelaria Radcy Prawnego Joanna Paluszkiewicz, Wszelkie Prawa Zastrzeżone
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
