import { Component } from 'preact';
import { SectionConfig } from '../../services/menu.service';
import { Heading } from '../ui/heading/heading';
import { Paragraph } from '../ui/paragraph/paragraph';
import styles from './about.module.css';

export class About extends Component<Props> {
    public render() {
        return (
            <section className={styles.about}>
                <div className={styles.aboutText}>
                    <Heading id={this.props.section.id} light={true} size='h2'>
                        {this.props.section.title}
                    </Heading>
                    <Paragraph light={true}>
                        Kancelarię założyłam prowadząc praktykę zawodową przy{' '}
                        <strong>Okręgowej Izbie Radców Prawnych w Poznaniu.</strong>
                    </Paragraph>
                    <Paragraph light={true}>
                        W swojej aktywności zawodowej <strong>doradzam przedsiębiorcom</strong> w ramach prowadzonej
                        przez nich działalności gospodarczej w zakresie prawa pracy, prawa zobowiązań oraz prowadzę
                        procesy sądowe czy też rejestruje znaki towarowe.
                    </Paragraph>
                    <Paragraph light={true}>
                        Świadczę usługi na rzecz <strong>klientów indywidualnych</strong> między innymi w sprawach
                        odszkodowawczych, rodzinnych oraz windykacyjnych.
                    </Paragraph>
                    <Paragraph light={true}>
                        Na co dzień współpracuję z <strong>doradcami podatkowymi</strong>, rzeczoznawcami majątkowymi a
                        także innymi specjalistami na terenie całego kraju.
                    </Paragraph>
                    <div className={styles.signatureWrapper}>
                        <div className={styles.signature}>Joanna Paluszkiewicz</div>
                        <div className={styles.signatureTitle}>Partner zarządzający</div>
                    </div>
                </div>
                <div className={styles.aboutImage}>
                    <div className={styles.aboutBkg}></div>
                </div>
            </section>
        );
    }
}

interface Props {
    section: SectionConfig;
}
