import { Component } from 'preact';
import { TopContactLink } from './top-contact-link/top-contact-link';
import style from './top-contact.module.css';

export class TopContact extends Component {
    private readonly phoneNumber = '510 69 00 69';
    private readonly email = 'kancelaria@paluszkiewicz.legal';
    public render() {
        return (
            <div className={style.topContact}>
                <div className={style.topContactItem}>
                    <TopContactLink type={'phone'} text={this.phoneNumber} />
                </div>
                <div className={style.topContactItem}>
                    <TopContactLink type={'email'} text={this.email} />
                </div>
            </div>
        );
    }
}
