import { Component } from 'preact';
import styles from './hamburger.module.css';

export class Hamburger extends Component<Props> {
    public render() {
        return (
            <button className={styles.wrapper} onClick={this.props.onClick}>
                <span className={styles.hamburger}>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </button>
        );
    }
}

interface Props {
    onClick?: () => void;
}
