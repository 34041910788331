import { Component } from 'preact';
import { MenuConfig, SectionConfig } from '../../services/menu.service';
import { CloseButton } from '../ui/close-button/close-button';
import { Hamburger } from '../ui/hamburger/hamburger';
import { MenuItem } from './menu-item';
import styles from './menu.module.css';

export class Menu extends Component<Props, State> {
    public state = {
        isMenuOpened: false
    };

    public render() {
        return (
            <menu className={styles.menu}>
                <div className={styles.navButton}>
                    <Hamburger onClick={() => this.toogleMenu()} />
                </div>
                <input type='checkbox' checked={this.state.isMenuOpened} />
                <nav className={styles.menuItemContainer}>
                    <div className={styles.closeButton}>
                        <CloseButton onClick={() => this.closeMenu()} />
                    </div>
                    <ul className={styles.navigation}>
                        {this.menu.map((entry) => (
                            <li>
                                <MenuItem anchor={entry.id} onClick={() => this.closeMenu()}>
                                    {entry.title}
                                </MenuItem>
                            </li>
                        ))}
                    </ul>
                </nav>
            </menu>
        );
    }

    private toogleMenu(): void {
        this.setState((prevState) => ({
            isMenuOpened: !prevState.isMenuOpened
        }));
    }

    private closeMenu(): void {
        this.setState({ isMenuOpened: false });
    }

    private get menu(): SectionConfig[] {
        return Object.values(this.props.config);
    }
}

interface Props {
    config: MenuConfig;
}

interface State {
    isMenuOpened: boolean;
}
