import { Component, h } from 'preact';
import { MenuConfig } from '../../services/menu.service';
import { Logo } from '../logo/logo';
import { Menu } from '../menu/menu';
import { MainHeading } from '../ui/main-heading/main-heading';
import styles from './header.module.css';

export class Header extends Component<Props> {
    public render() {
        return (
            <header class={styles.header}>
                <div className={styles.menuContainer}>
                    <div className={styles.menu}>
                        <Logo />
                        <Menu config={this.props.menuConfig} />
                        <div className={styles.mainHeading}>
                            <MainHeading>Kancelaria Paluszkiewicz</MainHeading>
                            <div className={styles.mottoWrapper}>
                                <p className={styles.motto}>
                                    Zakres obsługi prawnej dostosowujemy
                                    <br />
                                    do stale zmieniających się potrzeb klienta.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

interface Props {
    menuConfig: MenuConfig;
}
