export function getPractices(): Practice[] {
    return [
        {
            title: 'Obsługa prawna firm',
            text: 'W ramach prowadzonej praktyki kancelaria oferuje kompleksowe usługi obsługi prawnej firm, poczynając od analiz kontraktów, a kończąc na sporach sądowych i negocjacjach.'
        },
        {
            title: 'Obsługa podmiotów indywidualnych',
            text: 'Obejmuje bieżące doradztwo we wszystkich aspektach życia codziennego np: analiza umów, windykacja roszczeń, prawo rodzinne, prawo spadkowe.'
        },
        {
            title: 'Prawo pracy',
            text: 'Oferujemy pomoc prawną zarówno po stronie pracodawców jak i też pracowników oraz ubezpieczonych, w szczególności w zakresie przygotowywania kompleksowej dokumentacji związanej z rozwiązaniem stosunku pracy.'
        },
        {
            title: 'Postępowanie sporne',
            text: 'Zapewniamy specjalistów doświadczonych w zakresie negocjacji, arbitrażu i reprezentacji przed sądami.'
        },
        {
            title: 'Nieuczciwa konkurencja',
            text: 'Współpracujemy z klientami w zapobieganiu naruszeniom prawa konkurencji. Kancelaria poza wdrażaniem procedur zarzadzania ryzykiem prowadzi również praktyczne szkolenia dla kadry zarządzającej.'
        },
        {
            title: 'Prawo zobowiązań',
            text: 'Dziedzina ta obejmuje kompleksową analizę i przygotowywanie umów a następnie prowadzenie negocjacji z kontrahentami zarówno w sferze umów najmu, sprzedaży, świadczenia usług, umów w procesie inwestycyjnym.'
        },
        {
            title: 'Prawo rodzinne',
            text: 'Kancelaria zapewnia pomoc prawną w zakresie spraw rodzinnych: rozwodów, separacji, alimentów, władzy rodzicielskiej i kontaktów z dzieckiem. Zakres usług obejmuje porady, opinie prawne, sporządzanie pism oraz reprezentację Klienta.'
        },
        {
            title: 'Odszkodowania',
            text: 'Szkody osobowe i majątkowe (np: samochodowe). Kancelaria współpracuje z rzeczoznawcami oraz specjalistami z dziedziny mechaniki samochodowej zapewniając swoim klientom kompleksowe wsparcie.'
        },
        {
            title: 'Prawo własności intelektualnej',
            text: 'Kancelaria zapewnia wsparcia na etapie umownym (przygotowanie umowy z twórcami) a kończąc na rejestracji znaku towarowego lub stwierdzenia pierwszeństwa danego pomysłu. W ramach oferty prowadzone są również spory z zakresu udzielonej ochrony przed urzędem patentowym.'
        }
        // {
        //     title: 'Konsultacje online',
        //     text: 'W trosce o zdrowie klientów oferujemy spotkania prowadzone zdalnie. Usługa jest realizowana za pośrednictwem środków porozumienia się na odległość.'
        // },
        // {
        //     title: 'Konsultacje u Klienta',
        //     text: 'Częśc spraw wymaga ostrożnego i indywidualnego podejścia, z tego względu możliwe jest ustalenie miejsca spotkania w dogodnym dla klienta otoczeniu, w godzinach dopasowanych do jego indywidualnych potrzeb.'
        // }
    ];
}

export interface Practice {
    text: string;
    title: string;
    iconUrl?: string;
}
